:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.text-input {
  @apply appearance-none border-b-2 focus:border-primary-blue outline-none w-full py-2 placeholder:text-black;
}

.PhoneInput {
    @apply flex items-center;
  }
  
  .PhoneInput input{
    @apply pl-14 text-input;
  }
  
  .PhoneInputCountry {
    @apply absolute flex items-center border-black border-r pr-2;
  }
  
  .PhoneInputCountryIcon {
    @apply w-6 h-6;
  }
  
  .PhoneInputCountryIconImg {
    @apply rounded-full object-cover object-center w-full h-full;
  }
  
  .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
  }
  
  .PhoneInputCountrySelectArrow {
      display: block;
      content: '';
      width: var(--PhoneInputCountrySelectArrow-width);
      height: var(--PhoneInputCountrySelectArrow-width);
      margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
      border-style: solid;
      border-color: var(--PhoneInputCountrySelectArrow-color);
      border-top-width: 0;
      border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      border-left-width: 0;
      border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      transform: var(--PhoneInputCountrySelectArrow-transform);
      opacity: var(--PhoneInputCountrySelectArrow-opacity);
  }