@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-input {
  @apply appearance-none border-b-2 focus:border-primary-blue outline-none w-full py-2 placeholder:text-black;
}

.card-title {
  @apply text-2xl text-center font-bold;
}

.continue-button {
  @apply bg-primary-blue max-w-[400px] text-white py-3 font-bold flex-1 rounded-lg hover:bg-black;
}

.password-input {
  background: url(assets/EyeIcon.svg) no-repeat scroll center;
  background-position-x: calc(100% - 10px);
}
